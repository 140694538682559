.PhoneInputInput {
    border: 0.0625rem solid #ced4da;
    min-height: 2.25rem;
    height: 2.25rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    padding-left: calc(2.25rem  / 3);
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}
.PhoneInputInput::placeholder {
    opacity: .5;
}