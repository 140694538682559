.root {
    position: absolute;
    display: flex;
    padding: 10px;
  }
  
  .inner {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px solid rgba(0, 0, 0, .2);
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .male {
    background: #a4ecff;
  }
  
  .female {
    background: #fdaed8;
  }
  
  .isRoot {
    border-color: rgba(0, 0, 0, .4);
  }
  
  .isHover {
    border-color: rgba(0, 0, 0, .8);
  }
  
  .id {
    font-size: 7px;
    line-height: 1;
    opacity: .5;
  }
  
  .sub {
    position: absolute;
    top: 6px;
    right: 14px;
    width: 14px;
    height: 10px;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px 0;
    background: #fff;
    cursor: pointer;
  }
  
  .sub.male {
    background: #fdaed8;
  }
  
  .sub.female {
    background: #a4ecff;
  }