.root {
    display: flex;
    flex-direction: column;
    background: white;
    height: 100%;
  }
  
  .header {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 1px solid #ddd;
    z-index: 999;
  }
  
  .title {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
  }
  
  .version {
    display: block;
    padding: 4px 6px;
    font-size: 10px;
    line-height: 1;
    background: #eee;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .wrapper {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .wrapper_mobile {
    width: 100vw;
    height: 100vh;
  }
  
  .tree {
    background: #fff;
    border-radius: 4px;
  }
  
  .reset {
    position: absolute;
    right: 10px;
    top: 60px;
    padding: 4px 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    line-height: 1;
    cursor: pointer;
  }
  
  .details {
    position: absolute;
    left: 10px;
    top: 60px;
  }
  
  @media (max-width: 600px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .details {
      top: 100px;
    }
  }